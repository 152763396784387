/* DataTableDemo.css */
input{
    width: 100%;
}
.datatable-filter-demo .p-paginator .p-paginator-current {
    margin-left: auto;
}

.datatable-filter-demo .p-progressbar {
    height: .5rem;
    background-color: #D8DADC;
}

.datatable-filter-demo .p-progressbar .p-progressbar-value {
    background-color: #607D8B;
}

.datatable-filter-demo .table-header {
    display: flex;
    justify-content: space-between;
}



.datatable-filter-demo .p-datepicker {
    min-width: 25rem;
}

.datatable-filter-demo .p-datepicker td {
    font-weight: 400;
}

.datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-header {
    padding: 0.5rem;
    text-align: left;
    font-size: 1.5rem;
    border-width: .16rem 0;
}

.datatable-filter-demo .p-datatable.p-datatable-customers .p-paginator {
    padding: 1rem;
}

.datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-thead > tr > th {
    text-align: center;
    border-width: 0;
}

.datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td {
    text-align: center;
    cursor: auto;
}

.datatable-filter-demo .p-datatable.p-datatable-customers .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
}

.datatable-filter-demo .p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 850px) {
    .datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-thead > tr > th,
    .datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-tfoot > tr > td {
        display: none !important;
    }

    .datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-tbody > tr {
        border-bottom: 1px solid #dee2e6;
    }

    .datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td {
        text-align: center;
        align-items: center;
        display: block;
        border: 0 none !important;
        width: 100% !important;
        float: left;
        clear: left;
        border: 0 none;
    }

    .datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
        padding: .4rem;
        width: 50%;
        display: inline-block;
        margin: -.4rem 1rem -.4rem -.4rem;
        font-weight: bold;
    }


    .datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td .p-progressbar {
        margin-top: .5rem;
    }

}
